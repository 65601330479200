import React, {useState, useEffect, useCallback} from 'react';
import './InputPublicAddressBuy.css'
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap';
import AlertLogo from '../../assets/alertlogo.png'
import { useNavigate, Link, Router } from 'react-router-dom';
import WarningLogo from '../../assets/warninglogo.png'
import NumberFormat from 'react-number-format';
import NavbarSell from '../../Sell/Navbar/NavSell';
import Footer from '../../Sell/Footer/Footer'
import { checkByPublicAddressApi,checkRatesApi, registerPublicAddress } from '../../../services/Api';
import VerifyLogo from '../../assets/verify.png'
import { ToastContainer, toast } from 'react-toastify';

function InputPublicAddress() {
    const [public_address, setpublic_address]= useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [alertRegister, setAlertRegister] = useState(false);

    const [warningAlert, setWarningAlert] = useState(false);
    const [rateKpgBuy,setRateKpgBuy]= useState()
    const [alertMessage,setAlertMessage] = useState('')
    const [openVerify,setOpenVerify] = useState(false)
    const [paymentMethodUserInfo,setPaymentMethodUserInfo] = useState(false)
    const [fullname,setFullName] = useState('')
    const [email,setEmail] = useState('')
    const [phone,setphone] = useState('')
    const [noAccountBetaMask,setNoAccountBetaMask] = useState (false)
    const [limitAccountFull,setLimitAccountFull] = useState(false)
    const [emptyInput,setEmptyInput] = useState(false)
    const [alertEmail, setAlertEmail] = useState(false)
    const [alertFullname, setAlertFullname] = useState(false)
    const [alertPhoneNumber, setAlertPhoneNumber] = useState(false)
    const [alertPhoneNumberLength, setAlertPhoneNumberLength] = useState(false)
    const [alertRemoveLimit, setAlertRemoveLimit] = useState(false)



    const moveToPaymentMethodBuy = useNavigate();

    const [dataKepengQuantity, setDataKepengQuantity] = useState({
        quantity :0,
        convertKpgToIDR:0
    })
    

    function handleClick(){
         moveToPaymentMethodBuy('/checkout')
    }
    const getKpgRatesBuy = useCallback(async() => {
        const rateData = await checkRatesApi();
        setRateKpgBuy(rateData.buy)
        // console.log(rateData)

    },[checkRatesApi])

    useEffect(()=> {
        getKpgRatesBuy()   
        const orderKepengDataFromLocal = localStorage.getItem('kepeng-transaction')
        const dataKepengQuantity = JSON.parse(orderKepengDataFromLocal)
        setDataKepengQuantity(dataKepengQuantity)
    },[])

    const onSubmit = async () => {
        const data = {
            transaction_type: dataKepengQuantity?.transaction_type,
            public_address,
            amount_kpg:dataKepengQuantity?.quantity,
        }
        // empty public address check
        if(public_address ===''){
            setShowAlert(true)
            setEmptyInput(true)
            setLimitAccountFull(false)
            setNoAccountBetaMask(false)
            setTimeout(()=>{
                setShowAlert(false)
                setEmptyInput(false)
            }, 3000)
            console.log('Please input Public Address first')
        }
        //if not empty
        else{
           const response = await checkByPublicAddressApi(data)
           console.log('status--------',response?.status)
           console.log('message----',response?.message)
           //if status false
           if(response?.status === false) {
                console.log('status false')

                // if status false , message user doesn't have account
                if(response?.message === "Oops.. Looks like you haven't GrahaKPG account yet. Create now"){
                    console.log('doesnt have account')
                    // setOpenVerify(true)
                    // console.log('responses error:', response.message)
                    setAlertMessage(response.message)
                    setShowAlert(true)
                    setNoAccountBetaMask(true)
                    setLimitAccountFull(false)
                    // setPaymentMethodUserInfo(true)

                   
                    
                    // data that will be send to the API
                        const dataUnRegistered = {
                            name:fullname,
                            email:email,
                            phone:phone,
                            public_address,
                            transaction_type: dataKepengQuantity?.transaction_type,
                            amount_kpg:dataKepengQuantity?.quantity,
                            convertKpgToIDR:dataKepengQuantity?.convertKpgToIDR,
                            rate:dataKepengQuantity?.rate,
                            adminFee:dataKepengQuantity?.adminFee,
                            platformFee:dataKepengQuantity?.platformFee,
                            totalBill:dataKepengQuantity?.totalBill
                        }
                       
                        if(fullname === ""){
                            setAlertFullname(true)
                            // setShowAlert(false)
                            setTimeout(()=>{
                                setAlertFullname(false)
                            },3000)
                        }else if(phone ===""){
                            setAlertPhoneNumber(true)
                            // setShowAlert(false)
                            setTimeout(()=>{
                                setAlertPhoneNumber(false)
                            },3000)
                        }else if(phone.length < 7 || phone.length > 15){
                            setAlertPhoneNumberLength(true)
                            // setShowAlert(false)

                            setTimeout(()=>{
                                setAlertPhoneNumberLength(false)

                            },3000)
                        }else if(email ==="" || email.includes('@')=== false || email.includes('.com')=== false ){
                            console.log('please enter valid email format using @')
                            console.log('----- not using @',email.includes('@'))
                            console.log('----- not using .com', email.includes('.com'))


                            setAlertEmail(true)
                            // setShowAlert(false)

                            setTimeout(()=>{
                                setAlertEmail(false)
                            },3000)
                        } 
                        else{
                            const response = await registerPublicAddress(dataUnRegistered)
                            if(response.success === false){
                                console.log('----',response?.success)
                                // setShowAlert(false)
                                toast.error('Your public address is invalid')
                                console.log('false error data empty')
                                console.log('please fullfill all the form')
                                
                            }else{
                                localStorage.setItem('kepeng-transaction', JSON.stringify(dataUnRegistered))
                                console.log('phone number ----------',phone)
                                console.log('EMAIL ----------',email)
                                console.log('NAME ----------',fullname)


                                console.log(dataUnRegistered)
                                handleClick()
                            }

                        }
                        
                    // }
            }else if(response?.message === 'Your account is unregistered. '){
                setAlertMessage(response.message)
                setWarningAlert(true)
                setPaymentMethodUserInfo(true)
                const dataUnRegistered = {
                    name:fullname,
                    email:email,
                    phone:phone,
                    public_address,
                    transaction_type: dataKepengQuantity?.transaction_type,
                    amount_kpg:dataKepengQuantity?.quantity,
                    convertKpgToIDR:dataKepengQuantity?.convertKpgToIDR,
                    rate:dataKepengQuantity?.rate,
                    adminFee:dataKepengQuantity?.adminFee,
                    platformFee:dataKepengQuantity?.platformFee,
                    totalBill:dataKepengQuantity?.totalBill
                }
               
                if(fullname === ""){
                    setAlertFullname(true)
                    // setShowAlert(false)
                    setTimeout(()=>{
                        setAlertFullname(false)
                    },3000)
                }else if(phone ===""){
                    setAlertPhoneNumber(true)
                    // setShowAlert(false)
                    setTimeout(()=>{
                        setAlertPhoneNumber(false)
                    },3000)
                }else if(phone.length < 7){
                    setAlertPhoneNumberLength(true)
                    // setShowAlert(false)

                    setTimeout(()=>{
                        setAlertPhoneNumberLength(false)

                    },3000)
                }else if(email ==="" || email.includes('@')=== false || email.includes('.com')=== false ){
                    console.log('please enter valid email format using @')
                    console.log('----- not using @',email.includes('@'))
                    console.log('----- not using .com', email.includes('.com'))


                    setAlertEmail(true)
                    // setShowAlert(false)

                    setTimeout(()=>{
                        setAlertEmail(false)
                    },3000)
                } 
                else{
                    const response = await registerPublicAddress(dataUnRegistered)
                    if(response.success === false){
                        console.log('----',response?.success)
                        // setShowAlert(false)
                        toast.error('Your public address is invalid')
                        console.log('false error data empty')
                        console.log('please fullfill all the form')
                        
                    }else{
                        localStorage.setItem('kepeng-transaction', JSON.stringify(dataUnRegistered))
                        console.log('phone number ----------',phone)
                        console.log('EMAIL ----------',email)
                        console.log('NAME ----------',fullname)


                        console.log(dataUnRegistered)
                        handleClick()
                    }

                }


            } 
            
        else if(response?.message === "Oops.. It seems like you have reach the transaction limit. Verify to continue"){
                console.log('limit was full')
                    // setOpenVerify(true)
                setNoAccountBetaMask(false)
                const dataPublicAdrressKYC ={
                    transaction_type: dataKepengQuantity?.transaction_type,
                    public_address,
                    amount_kpg:dataKepengQuantity?.quantity,
                }

                localStorage.setItem('kepeng-transaction',JSON.stringify(dataPublicAdrressKYC))
                // console.log('responses error:', response.message)
                setAlertMessage(response.message)
                setShowAlert(true)
                setLimitAccountFull(true)
       
        }
        else if(response.message === "Your account is under verification. Please wait for a moment... "){
                setWarningAlert(true)
                setAlertMessage('Your account is under verification. Please wait for a moment... ')

        }else if (response?.message === response?.message?.includes('Verify Account to remove transaction limits')){
                setAlertRemoveLimit(true)
        }

        }else if (dataKepengQuantity?.quantity === "undefined"){
               toast.error('please re-input amount of kepeng')

        }
            
        else{
            if (response?.message === response?.message?.includes('Verify Account to remove transaction limits')){
                setAlertMessage(response?.message)
            }else{
                setAlertMessage(response?.message)

                setAlertRemoveLimit(true)

            
                console.log('response dataa -------',response?.user)
                console.log('good public address')
                console.log('-----warning-------')
                
                // setWarningAlert(true)
                // setTimeout(()=>{
                //     setWarningAlert(false)
                // }, 3000)
                
                    console.log('-----regis-------')

                    const dataRegistered = {
                        name:response?.name,
                        email:response?.email,
                        phone:response?.phone,
                        transaction_type: dataKepengQuantity?.transaction_type,
                        public_address,
                        amount_kpg:dataKepengQuantity?.quantity,
                        convertKpgToIDR:dataKepengQuantity?.convertKpgToIDR,
                        // rate:rateKpgBuy,
                        rate:dataKepengQuantity?.rate,
                        adminFee:dataKepengQuantity?.adminFee,
                        platformFee:dataKepengQuantity?.platformFee,
                        totalBill:dataKepengQuantity?.totalBill
                    }
                    localStorage.setItem('kepeng-transaction', JSON.stringify(dataRegistered))
                    console.log(dataRegistered)
                    setTimeout(()=>{
                        handleClick()
                    },5000)
            }
               
                    // handleClick()
                    // }
                

               
 

 
            }
            // else{
            //     setPaymentMethodUserInfo(true)
            //     localStorage.setItem('buy-kepeng', JSON.stringify(data))
            //     console.log(data)
            //     handleClick()
 
            // }

            // localStorage.setItem('Public-Address-Receiver', JSON.stringify(data))
            // console.log(data)
            // handleClick()
        }
    }
    return (
        <div>
             <NavbarSell/>
        
        <Container className="publicaddresscontainerBuy">
            <Form>
             <Row className="mainRowBuy px-4">
                    <Col className="col_inputAddress" xs={8}>
                    <Form.Label className="publicaddresslabelBuy">Your Public Address (2/3)</Form.Label>
                    <Form.Text className="fillpublicaddressBuy">
                    Enter the  wallet public address to receive Kepeng (KPG) that you buy.                   
                    </Form.Text>
                    <Form.Group>
                        <Form.Label className="publicaddressBuy">Receiver Public Address</Form.Label>
                        <Form.Control type="text" value={public_address} placeholder="Public Address" onChange={(event)=>setpublic_address(event.target.value)}/>
                    </Form.Group>
                    <Row>
                        {
                        showAlert?<Alert className="nothavewalletBuy" variant="danger">
                        <img className='alertLogoBuy'
                            src={AlertLogo}
                            alt="/"
                        />
                        {
                        limitAccountFull?<p className="nothavewallettextBuy">
                            {/* {alertMessage} */}
                            Oops.. It seems like you have reach the transaction limit.
                            <a className='create-now-public-address-buy-alert' 
                             target="_blank"
                             onClick={()=>{
                                setOpenVerify(true)
                            }}
                             ><strong>Verify to continue </strong></a>
                        </p>:null
                        }
                        {noAccountBetaMask?
                        <p className="nothavewallettextBuy">
                            {/* {alertMessage} */}Oops.. Looks like you haven't created GrahaKPG account yet. <a className='create-now-public-address-buy-alert' onClick={()=>{
                                setPaymentMethodUserInfo(true)
                            }}><strong>Create now</strong></a>
                        </p>:null
                        }
                         {emptyInput?
                        <p className="nothavewallettextBuy">
                            Please enter your public adderss
                        </p>:null
                        }
                        <p className='nothavewallettextBuy d-flex justify-content-end' 
                                        onClick={()=>setShowAlert(false)}
                                        style={{cursor:'pointer'}} >(x)</p>
                        </Alert>:null
                        }
                        
                        
                    </Row>
                    
                    <Row>
                        {
                            warningAlert?<Alert className="notactiveaccount" variant="warning">
                            <img className="cautionlogo" 
                            src={WarningLogo} alt='/'/>
                            <p className="notactiveaccounttext">
                                {alertMessage} 
                                <span className='mx-6 my-auto' onClick={()=>setWarningAlert(false)}
                                        style={{cursor:'pointer'}}>(x)</span>
                            {/* You have to activate your account to purchase Kepeng. <a><u><strong>Activated now</strong></u></a>   */}
                            </p>
                            
                            </Alert>:null
                        }
                        {
                          alertRemoveLimit?<Alert className="notactiveaccount" variant="warning">
                          <img className="cautionlogo" 
                          src={WarningLogo} alt='/'/>
                          <p className="notactiveaccounttext" style={{fontSize:'18px'}}>
                              {alertMessage?.length > 23 ? alertMessage?.substring(0,24):alertMessage}
                              <span onClick={()=>setOpenVerify(true)} style={{cursor:'pointer',textDecoration:'underline'}}>
                                  <strong>Verify Account</strong> 
                              </span>
                              to remove transaction limits  
                              <span className='mx-6 my-auto' onClick={()=>setAlertRemoveLimit(false)}
                                      style={{cursor:'pointer'}}>(x)</span>
                          {/* You have to activate your account to purchase Kepeng. <a><u><strong>Activated now</strong></u></a>   */}
                          </p>
                          
                          </Alert>:null  
                        }

                    </Row>
                    </Col>

                    <Col className="col_ordersummary" xs={5}>
                    <Form.Label className="ordersummaryBuy">Order Summary</Form.Label>
                    <Form.Text className="conversionidrBuy">
                        Buy - {dataKepengQuantity.quantity} KPG <span><NumberFormat 
                            value={dataKepengQuantity.convertKpgToIDR}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR  </span>
                    </Form.Text> 
                    <Form.Text className="fee">
                       Admin fee <span><NumberFormat 
                            value={dataKepengQuantity?.adminFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    <Form.Text className="fee">
                       Platform fee <span><NumberFormat 
                            value={dataKepengQuantity?.platformFee}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR </span>
                    </Form.Text>
                    
                    <Form.Text className="totalrecieveBuy">
                       Total bill <span>
                        <NumberFormat 
                            value={dataKepengQuantity.totalBill}
                            displayType="text"
                            thousandSeparator=","
                            decimalSeperator='.'/>
                             &nbsp;IDR  </span>
                    </Form.Text>
                    <Row className="allbuttonrowBuy">
                        <Row className="eachbtnBuy">
                            <Button className="buttonnextBuy" onClick={onSubmit} variant="primary"type="button">
                                Next
                            </Button>
                        </Row>
                        <Row className="eachbtnrowBuy">
                            <Link to='/kpg-buy' className="buttonbackBuy"  variant="light" >
                                Back
                            </Link>
                        </Row>
                    </Row>
                   
                  
                    </Col>
              

            </Row>
            </Form>
            {openVerify && <div id='id01' className='modalVerify'>
                    <div className='verifyBackground'>
                        <div className="verifyContainer">
                            <div className="body">
                                <Row className='d-flex flex-column'>
                                    <Col className='pt-5 px-4 '>
                                    <p className='d-flex justify-content-end' 
                                        onClick={()=>setOpenVerify(false)}
                                        style={{cursor:'pointer'}} >X</p>
                                        <img src={VerifyLogo} alt="/" style={{width:'135px', height:'135px'}}/>

                                        <h2 className='verify_title d-flex w-auto pt-5' style={{fontSize:'32px', fontWeight:'500', color:'#34495E', fontFamily:'inter'}}>
                                            Secure transactions with Verified Accounts
                                        </h2>
                                        <h2 className='verify_info d-flex w-auto px-3 pt-3' style={{fontSize:'16px', fontWeight:'300', color:'#A9A9A9', fontFamily:'inter'}}>
                                        For the purposes of convenience and security of transactions,you have to verify your account berfore make a transaction in GrahaKPG
                                        </h2>

                                        <a href="/verification_1_grahakpg" className='a_button_verify d-flex flex-row w-100 pt-3'>
                                            <button className='button_verify w-100' style={{height:'64px',color:'white', backgroundColor:'#34495E', borderRadius:'8px',fontSize:'20px',fontFamily:'inter', fontWeight:'500', textDecoration:'none !important'}}>
                                            Send Instruction
                                        </button>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>    
                </div>}
                {paymentMethodUserInfo && <div id='id01' className='modalVerify'>
                    <div className='verifyBackground'>
                        <div className="verifyContainer">
                            <div className="body">
                                <Row className='"register__form d-flex flex-column text-start'>
                                <Col className='px-4 py-auto pb-4 text-left'>
                                    <form action="">
                                        <p className='d-flex justify-content-end' 
                                        onClick={()=>setPaymentMethodUserInfo(false)}
                                        style={{cursor:'pointer'}} >X</p>
                                        <h2 className=' mt-5 text-left' 
                                        style={{fontSize:'32px',fontFamily:'inter', fontWeight:'500'}}>Personal Information</h2>
                                        
                                        <h2 className='w-75' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'300',color: "#A9A9A9"}}>You’ve input your name and email to continue the transaction</h2>
                                        
                                        <h2 className=' mt-4' for='email' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Email</h2>

                                       

                                        <input 
                                        className=' w-100 ps-2 input_register' 
                                        value={email}
                                        onChange={(e)=>setEmail(e.target.value)}
                                        style={{height:'62px', fontSize:'16px'}} 
                                        type="email" id="email" name="email"
                                        placeholder='Your Email' required/>
                                         <Row>
                                            {alertEmail?<h3 style={{fontSize:'16px', color:'red', fontFamily:'inter'}}>Please input your email(e.g: example@gmail.com)</h3>:null}
                                        </Row>


                                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Full Name</h2>

                                        <input 
                                        className=' w-100 ps-2 input_register' 
                                        value={fullname}
                                        onChange={(e)=>setFullName(e.target.value)}
                                        style={{height:'62px', fontSize:'16px'}} type="text" placeholder='Full name' />

                                        <Row>
                                            {alertFullname?<h3 style={{fontSize:'16px', color:'red', fontFamily:'inter'}}>Please input your full name</h3>:null}
                                        </Row>
                                        
                                        
                                        <h2 className=' mt-4' style={{fontSize:'16px',fontFamily:'inter', fontWeight:'500',color:'#34495E'}}>Phone Number</h2>
                                        <input 
                                        className=' w-100 ps-2 input_register' 
                                        value={phone}
                                        onChange={(e)=>setphone(e.target.value)}
                                        style={{height:'62px', fontSize:'16px'}} type="text" placeholder='Phone' required />
                                         <Row>
                                            {alertPhoneNumber?<h3 style={{fontSize:'16px', color:'red', fontFamily:'inter'}}>Please input your phone number</h3>:null}
                                        </Row>
                                        <Row>
                                            {alertPhoneNumberLength?<h3 style={{fontSize:'16px', color:'red', fontFamily:'inter'}}>Phone number length must be more than or equal to 7 and less than 15 </h3>:null}
                                        </Row>
                                          {/* {
                                        showAlert?<Alert className="nothavewalletBuy" variant="danger">
                                        {
                                        alertRegister?<p className="nothavewallettextBuy">
                                            Please fullfill all the form
                                        </p>:null
                                        }
                                        {
                                        alertPhoneNumber?<p className="nothavewallettextBuy">
                                            Phone number length must be more than or equal to 7 
                                        </p>:null
                                        }

                                        </Alert>:null
                                        } */}
                                        <a  className='a_button_verify d-flex flex-row w-100 pt-5'>
                                            <button className='button_verify w-100' 
                                            onClick={onSubmit}
                                            type='button'
                                            style={{height:'64px',color:'white', backgroundColor:'#34495E', borderRadius:'8px',fontSize:'20px',fontFamily:'inter', fontWeight:'500', textDecoration:'none !important'}}>
                                            Continue
                                        </button>
                                        </a>
                                    </form>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>    
                </div>}
                <ToastContainer/>
        </Container>
        <Footer/>
        </div>
    );
}

export default InputPublicAddress;