import React, { useRef } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { BrowserRouter as Router, Route, Routes, useRoutes } from 'react-router-dom'
import HomepageKPG from './components/Homepage/Homepage'
import InputNominalSell from './components/Sell/InputNominal/InputNominal';
import PaymentMethodBuy from './components/Buy/paymentMethod/PaymentMethodBuy';
import InputNominal from './components/Buy/InputNominalBuy/InputNominalBuy';
import InputPublicAddress from './components/Buy/InputPublicAddressBuy/InputPublicAddressBuy';
import InputPublicAddressSell from './components/Sell/InputPublicAddress/InputPublicAddress';
import BankAccount from './components/Sell/BankAccount/BankAccount';
import SellTransactionDetail from './components/Sell/SellTransactionDetail/SellTransactionDetail';
import BuyTransactionDetail from './components//Buy/BuyTransactionDetail/BuyTransactionDetail';
import TransactionHistoryDetail from './components/TransactionHistory/TransactionHistory';
import TransactionHistoryDetailBuy from './components/DetailHistoryTransaction/DetailHistoryTransaction';
import TransactionHistoryDetailSell from './components/DetailHistoryTransaction/DetailHistoryTransactionSell/DetailTransactionSell';
import TokenSmartContract from './components/TokenSmartContract/TokenSmartContract';
import PrivacyPolicyPage from './components/PrivacyPolicyPage/PrivacyPolicyPage';
import TermConditionPage from './components/TermCondition/TermConditionPage';
import Register from './components/Register/Register';
import Signin from './components/Signin/Signin';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ConfirmationRegister from './components/ConfirmationRegister/ConfirmationRegister';
import ChangedPassword from './components/ChangedPassword/ChangedPassword';
import ChooseCountry from './components/VerifiedAccountChooseCountry/ChooseCountry';
import VerificationStep2 from './components/VerifiedAccountIdentityInformation/VerifiedAccountIdentityInformation';
import VerificationStep3 from './components/VerificationStep3/VerificationStep3';
import FinishVerificationStep from './components/FinishVerificationStep/FinishVerificationStep';



function App(props) {

    const tawkMessengerRef = useRef();

    const handleMinimize = () => {
        tawkMessengerRef.current.minimize();
    };

    const onLoad = () => {
        console.log('onLoad works!');
    };

    return (
        <div>

            <TawkMessengerReact
                propertyId="6304908537898912e9649ba5"
                widgetId="1gb4t93h1"
                ref={tawkMessengerRef}
                onLoad={onLoad} />

            <Router>
                <Routes>
                    <Route path='/' element={<HomepageKPG />} />
                    <Route path='/kpg-buy' element={<InputNominal />} />
                    <Route path='/receiver-info' element={<InputPublicAddress />} />
                    <Route path='/checkout' element={<PaymentMethodBuy />} />
                    <Route path="/buy-transaction-detail" element={<BuyTransactionDetail />} />
                    <Route path="/transaction-history-detail" element={<TransactionHistoryDetail />} />
                    <Route path='/kpg-sell' element={<InputNominalSell />} />
                    <Route path='/seller-info' element={<InputPublicAddressSell />} />
                    <Route path='/seller-bank-info' element={<BankAccount />} />
                    <Route path="/sell-transaction-detail" element={<SellTransactionDetail />} />
                    <Route path={`/detail_transaction/:id`} element={<TransactionHistoryDetailBuy />} />
                    <Route path={`/detail_transaction_sell/:id`} element={<TransactionHistoryDetailSell />} />
                    <Route path={"/token_smart_contract"} element={<TokenSmartContract />} />
                    <Route path={"/privacy_policy_grahakpg"} element={<PrivacyPolicyPage />} />
                    <Route path={"/term_condition_grahakpg"} element={<TermConditionPage />} />
                    <Route path={"/register_grahakpg"} element={<Register />} />
                    <Route path={"/signin_grahakpg"} element={<Signin />} />
                    <Route path={"/forgot_password_grahakpg"} element={<ForgotPassword />} />
                    <Route path={"/confirmation_register_grahakpg"} element={<ConfirmationRegister />} />
                    <Route path={"/changed_password_grahakpg"} element={<ChangedPassword />} />
                    <Route path={"/choose_country"} element={<ChooseCountry />} />
                    <Route path={"/input_identity_form"} element={<VerificationStep2 />} />
                    <Route path={"/upload_identity_photo"} element={<VerificationStep3 />} />
                    <Route path={"/finish_verifivation_grahakpg"} element={<FinishVerificationStep />} />

                </Routes>
            </Router>

        </div>
    );
}

export default App;