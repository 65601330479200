import React, { useCallback, useEffect, useState } from 'react';
import './DetailHistoryTransactionBuy.css'
import copyIcon from '../../assets/copy-icon.png'
import NumberFormat from 'react-number-format';
import { Container, Form, Row, Col, Button, Alert, Fade } from 'react-bootstrap';
import NavbarSell from '../../Sell/Navbar/NavSell';
import Footer from '../../Sell/Footer/Footer';
import { Link, useParams, useRoutes } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import BriLogo from '../../assets/bri.svg'
import { checkPaymentMethod, getDetailHistoryTransaction } from '../../../services/Api';

function DetailHistoryTransactionBuy() {

        //   const {query,isReady} =useRoutes();
        const [paymentLogo, setPaymentLogo] = useState('')
        const params = useParams();
        console.log(params);
        const [bankUrl, setBankUrl] = useState('')
        const [statusUnpaid, setStatusUnpaid] = useState(false)
        const [onProgress, setOnProgress] = useState(false)
        const [statusComplete, setStatusComplete] = useState(false)
        const [statusExpired, setStatusExpired] = useState(false)
        const [copied, setCopied] = useState(false)
        const [copiedId, setCopiedId] = useState(false)
        const [textCopied, setTextCopied] = useState('Copy')
        const [textCopiedIdTransaction, setTextCopiedIdTransaction] = useState('Copy')

        const [openQris, setOpenQris] = useState(false)

        //step transfer
        const [stepBNI, setStepBNI] = useState(false)
        const [stepBCA, setStepBCA] = useState(false)
        const [stepBRI, setStepBRI] = useState(false)
        const [stepMandiri, setStepMandiri] = useState(false)
        const [stepPermata, setStepPermata] = useState(false)
        const [stepIndomaret, setStepIndomaret] = useState(false)
        const [stepAlfamart, setStepAlfamart] = useState(false)









        const [dataDetailHistory, setDataDetailHistory] = useState('')
        //   console.log('status',dataDetailHistory)
        //   const thisProduct = dataDetailHistory.find(prod => prod.code === thisDetailId)


        const getDetailTransaction = useCallback(async (id) => {
                const dataHistory = await getDetailHistoryTransaction(id)
                setDataDetailHistory(dataHistory)
                //   console.log(dataHistory)
                //   console.log('check value',dataHistory?.[0].status)
        }, [getDetailHistoryTransaction])

        const getPaymentList = useCallback(async () => {
                const paymentList = await checkPaymentMethod()
                setPaymentLogo(paymentList)
                // console.log("payment list api-----", paymentList)
                // console.log("modern store", paymentList?.store?.['modern store'])
                // console.log("VA", paymentList?.store?.["virtual account"])
                // console.log("length va--------", paymentLogo?.store?.['virtual account']?.length)
                // console.log('detail history check ---',dataDetailHistory)



        }, [checkPaymentMethod])

        function paymentLogoCheck(txDetail, paymentUrl) {
                // console.log("detail -----",dataDetailHistory)
                const lenghtumber = 0;
                // for(const i = 0; i < paymentLogo?.store?.['virtual account'].length;i++){
                //         lenghtumber += i

                // }
                // console.log('length array',lenghtumber)
                paymentUrl?.store?.['modern store']?.map((bank) => {
                        if (bank.payment_code === txDetail?.payment_method_code) {
                                setBankUrl(bank?.payment_logo)
                        }
                })

                paymentUrl?.store?.['virtual account']?.map((bank) => {
                        if (bank.payment_code === txDetail?.payment_method_code) {
                                setBankUrl(bank?.payment_logo)
                        }
                })
                console.log("payment list ------", paymentLogo)


                //   if(dataDetailHistory?.[0].paymemt_method_code === paymentLogo?.store?.['virtual account']?.payment_code){
                //           console.log('----logo----',paymentLogo?.store?.['virtual accouny']?.payment_logo )
                //   }

        }


        useEffect(() => {
                //   console.log('id buy detail',params.id)
                //   console.log('data id ------',dataDetailHistory?.data?.code)
                //   console.log('data type',dataDetailHistory?.[0].status)
                getDetailTransaction(params.id)
                getPaymentList()
                //   console.log('payment cpde', dataDetailHistory[0]?.payment_method_code)
                //   if(dataDetailHistory[0]?.payment_method_code === 'BNIVA'){
                //           setStepBNI(true)
                //           setStepBRI(false)
                //           setStepMandiri(false)
                //           setStepPermata(false)
                //           setStepIndomaret(false)
                //           setStepAlfamart(false)
                //   }else if(dataDetailHistory[0]?.payment_method_code === 'BRIVA'){
                //         setStepBNI(false)
                //         setStepBRI(true)
                //         setStepMandiri(false)
                //         setStepPermata(false)
                //         setStepIndomaret(false)
                //         setStepAlfamart(false)
                // }else if(dataDetailHistory[0]?.payment_method_code === 'MANDIRIVA'){
                //         setStepBNI(false)
                //         setStepBRI(false)
                //         setStepMandiri(true)
                //         setStepPermata(false)
                //         setStepIndomaret(false)
                //         setStepAlfamart(false)
                // }else if(dataDetailHistory[0]?.payment_method_code === 'PERMATAVAC'){
                //         setStepBNI(false)
                //         setStepBRI(false)
                //         setStepMandiri(false)
                //         setStepPermata(true)
                //         setStepIndomaret(false)
                //         setStepAlfamart(false)
                // }else if(dataDetailHistory[0]?.payment_method_code === 'INDOMARET'){
                //         setStepBNI(false)
                //         setStepBRI(false)
                //         setStepMandiri(false)
                //         setStepPermata(false)
                //         setStepIndomaret(true)
                //         setStepAlfamart(false)
                // }else if(dataDetailHistory[0]?.payment_method_code === 'ALFAMART'){
                //         setStepBNI(false)
                //         setStepBRI(false)
                //         setStepMandiri(false)
                //         setStepPermata(false)
                //         setStepIndomaret(false)
                //         setStepAlfamart(true)
                // }








                // if(isReady){
                //         console.log("router isready",query.id)

                // }else{
                //         console.log("router not ready")
                // }

        }, [])

        useEffect(() => {
                if (dataDetailHistory && paymentLogo) {
                        paymentLogoCheck(dataDetailHistory?.[0], paymentLogo)

                }
                if (dataDetailHistory?.[0]?.status === "on progress") {
                        setOnProgress(true)
                } else if (dataDetailHistory?.[0]?.status === "waiting for transfer") {
                        setStatusUnpaid(true)
                } else if (dataDetailHistory?.[0]?.status === "complete") {
                        setStatusComplete(true)
                } else if (dataDetailHistory?.[0]?.status === "expired") {
                        setStatusExpired(true)
                } else if (dataDetailHistory?.[0]?.status === "unpaid") {
                        setStatusUnpaid(true)
                }
                if (copied === true) {
                        setTextCopied('Copied')
                        setTimeout(() => {
                                setCopied(false)
                        }, 3000)
                } else {
                        setTextCopied('Copy')
                }
                if (copiedId === true) {
                        setTextCopiedIdTransaction('Copied')
                        setTimeout(() => {
                                setCopiedId(false)
                        }, 3000)
                } else {
                        setTextCopiedIdTransaction('Copy')
                }


                console.log('status info-------', dataDetailHistory?.[0]?.payment_method_code)
        })

        return (
                <div>
                        <Container className="contain-all-history-detail-transaction-buy">
                                <Row className="headerRow-detail-history-transaction-buy">
                                        <Col className="headerCol" xs={6}>
                                                <Form.Label className="tokentransferlabel"> Virtual Account
                                                        <Row className='bri_logo_history_transaction justify-content-between'>
                                                                <img src={bankUrl} alt="/" className='briLogoDetailHistoryTransaction' />
                                                        </Row>
                                                </Form.Label>

                                        </Col>

                                </Row>
                                <Row className="bodyRow-detail-history-transaction-Buy" >
                                        <Col className="bodyCol" xs={6}>
                                                <Form.Label className="finishTransferBeforeLabel">Finish Transfer Before</Form.Label>
                                                <Form.Label className="transfertime">{dataDetailHistory?.[0]?.exp_date}</Form.Label>
                                                <Form.Label className="transfel-nominal-label">Transfer Nominal </Form.Label>
                                                <Form.Label className="total-nominal-kpg">
                                                        <NumberFormat
                                                                value={dataDetailHistory?.[0]?.total}
                                                                displayType="text"
                                                                thousandSeparator=","
                                                                decimalSeperator='.' />
                                                        &nbsp;IDR
                                                </Form.Label>
                                                {dataDetailHistory?.[0]?.payment_method_code === "QRISPAY" &&
                                                        <a className="open-Payment-Detail-Href" href={dataDetailHistory?.[0]?.url} target="_blank">Open QRIS code </a>
                                                }
                                                <Form.Label className="transfel-nominal-label">Kepeng amount </Form.Label>
                                                <Form.Label className="total-nominal-kpg">{dataDetailHistory?.[0]?.amount_kpg} KPG</Form.Label>
                                                <Form.Label className="sendtopublicaddresslabel">Virtual Account Number </Form.Label>

                                                <Form.Label className="user-public-address-buy">{dataDetailHistory?.[0]?.payment_code}
                                                        <CopyToClipboard text={dataDetailHistory?.[0]?.payment_code}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-public-address">
                                                                        <img className="logocopy" src={copyIcon} alt="" />{textCopied}
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>
                                                <Form.Label className="user-public-address-buy-mobile">{dataDetailHistory?.[0]?.payment_code?.length > 15 ? dataDetailHistory?.[0]?.payment_code.substring(0, 10) + "..." : dataDetailHistory?.[0]?.payment_code}
                                                        <CopyToClipboard text={dataDetailHistory?.[0]?.payment_code}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-public-address">
                                                                        <img className="logocopy" src={copyIcon} alt="" />{textCopied}
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>
                                                <Form.Label className="user-public-address-buy-ipad">{dataDetailHistory?.[0]?.payment_code?.length > 20 ? dataDetailHistory?.[0]?.payment_code.substring(0, 20) + "..." : dataDetailHistory?.[0]?.payment_code}
                                                        <CopyToClipboard text={dataDetailHistory?.[0]?.payment_code}
                                                                onCopy={() => setCopied(true)}
                                                        >
                                                                <span className="spanlogo-public-address">
                                                                        <img className="logocopy" src={copyIcon} alt="" />{textCopied}
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>
                                                <Form.Label className="transfer-status">Payment Status </Form.Label>
                                                {
                                                        onProgress ? <Alert className="On-progress-alert-unpaid" variant="warning">
                                                                {dataDetailHistory?.[0]?.status}
                                                        </Alert> : null
                                                }
                                                {
                                                        statusComplete ? <Alert className="On-progress-alert-complete" variant="warning">
                                                                {dataDetailHistory?.[0]?.status}
                                                        </Alert> : null
                                                }
                                                {
                                                        statusExpired ? <Alert className="On-progress-alert-expired" variant="warning">
                                                                {dataDetailHistory?.[0]?.status}
                                                        </Alert> : null
                                                }
                                                {
                                                        statusUnpaid ? <Alert className="On-progress-alert-history" variant="warning">
                                                                {dataDetailHistory?.[0]?.status}
                                                        </Alert> : null
                                                }
                                                <hr className="line" />

                                                <Form.Label className="transfer-status">Transaction Type </Form.Label>
                                                <Alert className="buy-alert-history" variant="success">{dataDetailHistory?.[0]?.transaction_type}</Alert>

                                                <Form.Label className="id-transaction-label">Id Transaction </Form.Label>

                                                <Form.Label className="id-transaction-detail-history">{params.id}

                                                        <CopyToClipboard text={params.id}
                                                                onCopy={() => setCopiedId(true)}
                                                        >
                                                                <span className="spanlogo-id-transaction">
                                                                        <img className="logocopy" src={copyIcon} alt="" /> {textCopiedIdTransaction}
                                                                </span>
                                                        </CopyToClipboard>
                                                </Form.Label>





                                                {/* </Form.Label>
                    <Form.Label className="id-transaction-mobile" id="id-transaction-mobile">{ transactionId?.length > 15 ? transactionId.substring(0,15)+"...":transactionId} 
                   
                    <span className="spanlogo-id-transaction-mobile">
                            
                            <img  className="logocopy" src={copyIcon} alt="" /> 
                            <span className="copy">Copy</span> 
                    </span>
                  
                    </Form.Label> */}

                                                {/* <img  className="logocopy" src={copyIcon} alt="" />
                    <Form.Text className="copy-public-address">Copy</Form.Text> */}


                                                {/* <hr className="line" /> */}
                                                {/* {dataDetailHistory[0]?.payment_method_code === "BRIVA" &&  <Row> */}
                                                {/* 
                                                <p>Anda dapat melakukan transfer KPG dengan mengikuti langkah berikut: </p>
                                                <h4>Cara pembayaran via ATM BRI</h4>
                                                <ol>
                                                        <li>Pada menu utama, pilih Transaksi Lain</li>
                                                        <li>Pilih Pembayaran</li>
                                                        <li>Pilih Lainnya</li>
                                                        <li>Pilih BRIVA</li>
                                                        <li>Masukkan Nomor BRIVA 124129357058596749</li>
                                                        <li>Jumlah pembayaran,
                                                                nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                                                                Jika informasi yang dicantumkan benar,pilih kirim</li>
                                                        <li>Pembayaran telah selesai. Simpan bukti pembayaran anda.</li>
                                                </ol>
                                                <h4>Cara pembayaran via Internet Bank BRI</h4>
                                                <ol>
                                                        <li>Masuk pada Internet Banking BRI</li>
                                                        <li>Pilih menu Pembayaran dan pembelian</li>
                                                        <li>Pilih sub menu BRIVA</li>
                                                        <li>Masukkan Nomor BRIVA 124129357058596749</li>
                                                        <li>Jumlah pembayaran,
                                                                nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                                                                Jika informasi yang dicantumkan benar,pilih kirim</li>
                                                        <li>Masukan password dan mToken, pilih kirim</li>
                                                        <li>Pembayaran telah selesai. Simpan bukti pembayaran anda. Untuk mencetak bukti transaksi, pilih Cetak</li>
                                                </ol>
                                                <h4>Cara pembayaran via BRI mobile</h4>
                                                <ol>
                                                        <li>Masuk ke dalam aplikasi BRI Mobile, pilih Mobile Banking BRI</li>
                                                        <li>Pilih Pembayaran, lalu pilih BRIVA</li>
                                                        <li>Masukkan Nomor BRIVA 124129357058596749</li>
                                                        <li>Jumlah pembayaran,
                                                                nomor pembayaran dan nama merchant akan muncul pada halaman konfirmasi pembayaran.
                                                                Jika informasi yang dicantumkan benar,pilih Continue</li>
                                                        <li>Masukkan Mobile Banking BRI PIN, pilih Ok</li>
                                                        <li>Pembayaran telah selesai. Simpan notifikasi sebagai bukti pembayaran</li>
                                                </ol> */}
                                                {/* </Row>
                } */}





                                        </Col>
                                        <Link to='/' className="back-to-homepage">
                                                <u>
                                                        Back to homepage
                                                </u>
                                        </Link>
                                </Row>

                        </Container>
                </div>
        )
}

export default DetailHistoryTransactionBuy