import React from 'react'
import GrahaLogo from '../assets/graha_logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Dropdown } from 'react-bootstrap'
import phoneContactLogo from '../assets/phone.png'
import emailContactLogo from '../assets/email.png'
import instagramLogo from '../assets/instagram.png'
import facebookLogo from '../assets/facebook.png'


const Navbar = () => {
    return (
        <nav className="navbar-homepage navbar navbar-expand-lg bg-dark sticky-top navbar_homepage">
            {/* <div className="container"> */}
            <a className="navbar-brand ps-5" href="/"><img className='logo' src={GrahaLogo} alt='/' href='/' /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
                <FontAwesomeIcon icon={faBars} style={{ color: '#fff' }} className='pe-5' />
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto pe-5">
                    <li className="nav-item active">
                        <a className="nav-link" aria-current="page" href="/">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/token_smart_contract">Token Smart Contract</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " aria-current="page" href="/transaction-history-detail">Transaction History</a>
                    </li>
                    <li className="nav-item">
                        {/* <a className="nav-link" href="#">About</a> */}
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                Info
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Bantuan</Dropdown.Item>
                                <Dropdown.Item href="https://graha-kpg.gitbook.io/faq-grahakpg/" target="_blank">FAQ</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Blog</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Tutorial</Dropdown.Item>
                                <hr className='d-flex w-75 mx-auto' />
                                <Dropdown.Item>
                                    <div className="social_media_icon_navbar d-flex flex-row w-auto justify-content-between ps-3">
                                        <a href='https://wa.me/+6281383690090' target="_blank"><img className='social-media-navbar' src={phoneContactLogo} alt="/" /></a>
                                        <a href='mailto:admin@grahakpg.com' target="_blank"><img className='social-media-navbar' src={emailContactLogo} alt="/" /></a>
                                        <a href='https://instagram.com/grahakpg?igshid=YmMyMTA2M2Y=' target="_blank"><img className='social-media-navbar' src={instagramLogo} alt="/" /></a>
                                        <a href='https://www.facebook.com/profile.php?id=100079333405862' target="_blank"><img className='social-media-navbar' src={facebookLogo} alt="/" /> </a>
                                    </div>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                    {/* <li className="nav-item my-auto">
                        <a className="nav-link-signin border-0" href="/signin_grahakpg"><button className='signin_Homepage'>Sign in</button></a>
                        </li>
                        <li className="nav-item my-auto">
                        <a className="nav-link-register" href="/register_grahakpg"><button className='register_Homepage'>Register</button></a>
                        </li> */}
                </ul>
            </div>
            {/* </div> */}
        </nav>
    )
}

export default Navbar