import React, { useEffect } from 'react';
import GrahaFooterLogo from '../assets/graha_logo.png'
import {Container} from 'react-bootstrap'
import {Col, Row, Form } from 'react-bootstrap';
import { useState } from 'react';
import copyIcon from '../assets/copy-icon.png'
import phoneImage from '../assets/Phone1.png'
import phoneImageStepTwo from '../assets/Phone2.png'
import LogoStep1 from '../assets/step-1.png'
import LogoStep2 from '../assets/step-2.png'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './TokenSmartContract.css'
import Navbar from '../Homepage/Navbar';
import Footer from '../Homepage/Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup'

function TokenSmartContract() {
    const [addressValue,setAddressValue] = useState('')
    const [copied,setCopied] = useState(false)
    const [textCopied,setTextCopied] = useState('Copy')

    const [publicAddress, setPublicAddress] = useState('0xeccd68E23CA0D0DD2184F20Db728BA08339FE602')
    useEffect(()=>{
        if(copied === true){
            setTextCopied('Copied')
            setTimeout(()=>{
                    setCopied(false)
            },3000)
    }else{
            setTextCopied('Copy')
    }
    })
    console.log(addressValue)
    function notify() {
        toast.dark("Hey 👋, see how easy!");
      }
    // useEffect (()=>{
    //     if(copied){
    //         copiedAlert()
    //     }
    // })
    return (
    <div>
        <Navbar/>
         <div className="header-wrapper-token-transaction">
            <div className="main-info p-8">
                <h2 className='info-1 smart-contract-public-address '><strong> Kepeng (KPG) Smart Contract </strong> <span className='span-header-decentralized'><br/>for decentralized wallet </span></h2>
                
            </div>
        </div>
        <div className="body-token-smart-contract">
        <Container className='public-address-card '>
        
            <Row className='justify-content-between align-items-center mx-0 mt-5 changer_input_main_info_wrapper' >
                <Form.Label 
                className="smart-contract-public-address" 
                value="0xeccd68E23CA0D0DD2184F20Db728BA08339FE602"
                onChange={(event)=>setAddressValue(event.target.value)} 
                 > {publicAddress}
                <CopyToClipboard text={publicAddress} onCopy={()=>setCopied(true)}>
                <span className="spanlogo-public-address-smart-contract">
                    <img className="logocopy-smart-contract"  src={copyIcon} alt="" /><span className="copy-public-address-token-smart-contract">Copy</span>
                </span>
                </CopyToClipboard>
                </Form.Label>

                <Form.Label 
                className="smart-contract-public-address-ipad" 
                value="0xeccd68E23CA0D0DD2184F20Db728BA08339FE602"
                onChange={(event)=>setAddressValue(event.target.value)} 
                >  {publicAddress>20?publicAddress.substring(0,22)+"...":publicAddress}
                 <CopyToClipboard text={publicAddress} onCopy={()=>setCopied(true)}>
                <span className="spanlogo-public-address-smart-contract">
                <img  className="logocopy-smart-contract" src={copyIcon} alt="" /><span className="copy-public-address-token-smart-contract">{textCopied}</span>
                </span>
                </CopyToClipboard>
                </Form.Label>

                <Form.Label 
                className="smart-contract-public-address-mobile" 
                value="0xeccd68E23CA0D0DD2184F20Db728BA08339FE602"
                onChange={(event)=>setAddressValue(event.target.value)} 
                > {publicAddress>20?publicAddress.substring(0,13)+"...":publicAddress}
                <CopyToClipboard text={publicAddress} onCopy={()=>setCopied(true)}>
                <span className="spanlogo-public-address-smart-contract">
                    <img  className="logocopy-smart-contract" src={copyIcon} alt="" /><span className="copy-public-address-token-smart-contract">{textCopied}</span>
                </span>
                </CopyToClipboard>
                </Form.Label>
                <Form.Label 
                className="smart-contract-public-address-desktop d-flex flex-row w-auto justify-content-center mx-auto px-5" 
                value="0xeccd68E23CA0D0DD2184F20Db728BA08339FE602"
                onChange={(event)=>setAddressValue(event.target.value)}
                > {publicAddress}
                <CopyToClipboard text={publicAddress} onCopy={()=>setCopied(true)}>
                <span className="spanlogo-public-address-smart-contract">
                    <img  className="logocopy-smart-contract" src={copyIcon} alt="" /><span className="copy-public-address-token-smart-contract">{textCopied}</span>
                </span>
                </CopyToClipboard>
                </Form.Label>
            </Row>
            <Row className="step-one-import">
                <Col className="img-phone-example-step" xs={4}>
                <Form.Label className="phone-lable-img">
                    <img src={phoneImage} alt="" />
                
                </Form.Label>
                </Col>
                <Col className="text-example-step" xs={8}>
                <Row className="lable-text-how-to-import">
                {/* <Form.Label className="step-import-kpg"> <span className="import-kpg"> import KPG into Metamask Wallet?</span></Form.Label> */}
                <h2 className='step-import-kpg" '><strong> How to </strong> <span className='import-kpg"'> import KPG into Metamask Wallet? </span></h2>
                </Row>
                <Row className="step-logo1">
                <img className="step-import" src={LogoStep1} /><span className='step-text-wrapper'><h3 className="steptext">Make sure you’ve use Avalanche Network. In home page, click “import token”</h3></span>
                
                </Row>
                </Col>
            </Row>
            <Row className="step-two-import">
            <Col className="img-phone-example-step" xs={4}>
                <Form.Label className="phone-lable-img2">
                    <img className='phone_step_2_img' src={phoneImageStepTwo} alt="" />
                
                </Form.Label>
                </Col>
                <Col className="text-example-step" xs={8}>
                <Row className="lable-text-how-to-import">
                {/* <Form.Label className="step-import-kpg"> <span className="import-kpg"> import KPG into Metamask Wallet?</span></Form.Label> */}
                </Row>
                <Row className="step-logo2">
                <img className="step-import-2" src={LogoStep2} /><span><h3 className="steptext2">Copy Kepeng Token Address above, put it on Token Address field in Metamask App. Token Symbol and Token of Precision will be automatically filled after you paste the Kepeng Token Address. Then click Import</h3></span>
                
                </Row>
                </Col>
            </Row>
          
                    

     

    </Container>

        </div>
            <Footer/>
    </div>

    
    );
}

export default TokenSmartContract;