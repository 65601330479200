import React, {useEffect, useState} from 'react'
import Navbar from '../Homepage/Navbar'
import Form from 'react-bootstrap/Form';
import { Container,Row,Col, Alert } from 'react-bootstrap'
import './VerificationStep3.css'
import Button from '@material-ui/core/Button';
import FooterVerifyAccount from '../VerifiedAccountChooseCountry/FooterVerifyAccount/FooterVerifyAccount';
import { useNavigate } from 'react-router-dom';
import { checkVerifyKTPAPI, checkVerifyPassportAPI } from '../../services/Api';
import { ToastContainer, toast } from 'react-toastify';

const VerificationStep3 = () => {

    const [valueImg, setValueImg]= useState('No file is selected')
    const [imgCardSize,setImgCardSize] =useState (0)
    const [imgPhotoSelfSize,setPhotoSelfSize] =useState (0)
    const [valueIdentity,setValueIdentiy] =useState ('')


    const [valueImgWithYourIdentityCard, setValueImgWithYourIdentityCard]= useState('No file is selected')
    const [dataKyc,setDataKyc]= useState([])
    const [show, setShow] = useState(false);
    const [checked, setChecked]= useState(false)

    const moveToFinishVerificationPage = useNavigate()

    function handleClick (){
        moveToFinishVerificationPage('/finish_verifivation_grahakpg')
    }

    function handlerImgIdentityForm(e){
        console.log('IDENTITY INFO----',e.target.files[0])
        const imgIdentity = e.target.files[0]
        // console.log('1-----',URL.createObjectURL(imgIdentity))
        // setValueImg(URL.createObjectURL(imgIdentity))
        setImgCardSize(imgIdentity?.size)
        setValueImg(imgIdentity?.name)
    }

    // function handlerImgSize(e){
    //     const imgSize = e.target.files[0]

    //     if (imgSize?.size >7000000){
    //         setShow(true)
    //     }
    // }

    console.log('IDENTITY INFO----',valueImg)
    function handlerImgWithIdentityForm(e){
        console.log('IDENTITY INFO WITH YOUR FACE---',e.target.files[0])
      
        const imgIdentity = e.target.files[0]
        console.log('img---',imgIdentity?.size)
        if (imgIdentity?.size >7000000){
            setShow(true)
            setTimeout(()=>{
                setShow(false)
            },3000)
        }
        else{
            setValueImgWithYourIdentityCard(imgIdentity?.name)
        }
        // console.log('1-----',URL.createObjectURL(imgIdentity))
        // setValueImgWithYourIdentityCard(URL.createObjectURL(imgIdentity))
        setPhotoSelfSize(imgIdentity?.size)
        setValueImgWithYourIdentityCard(imgIdentity?.name)
    }
    useEffect(()=>{
        const kycInfoFromLocal = localStorage.getItem('kyc-information')
        const kycInformation = JSON.parse(kycInfoFromLocal)
        setDataKyc(kycInformation)
        // setValueIdentiy(kycInformation?.indentityValue)
    },[])

    const onSubmitBack = () =>{
        const dataBack =
        {
            valueCountry:dataKyc?.country,
            public_address:dataKyc?.public_address
        }
        localStorage.setItem('kyc-information',JSON.stringify(dataBack))
    }

    const onSubmit = async () => {
        const dataKTP = new FormData()
        dataKTP.append('public_address',dataKyc?.public_address)
        dataKTP.append('country',dataKyc?.country)
        dataKTP.append('identity_card',valueImg)
        dataKTP.append('photo_self',valueImgWithYourIdentityCard)
        dataKTP.append('nik',dataKyc?.ktpId)
        dataKTP.append('name',dataKyc?.fullname)
        dataKTP.append('address',dataKyc?.address)
        dataKTP.append('gender',dataKyc?.gender)
        dataKTP.append('phone',dataKyc?.phoneNumber)
        dataKTP.append('email',dataKyc?.public_address)
        dataKTP.append('indentityValue',dataKyc?.indentityValue)

        // const dataKTP = {
        //     public_address:dataKyc?.public_address,
        //     country:dataKyc?.country,
        //     identity_card:valueImg,
        //     photo_self:valueImgWithYourIdentityCard,
        //     nik:dataKyc?.ktpID,
        //     name:dataKyc?.fullName,
        //     address:dataKyc?.address,
        //     gender:dataKyc?.gender,
        //     phone:dataKyc?.phoneNumber,
        //     email:dataKyc?.email,
        //     indentityValue:dataKyc?.indentityValue
        // }

        const dataPassport = new FormData()
        dataPassport.append('public_address',dataKyc?.public_address)
        dataPassport.append('country',dataKyc?.country)
        dataPassport.append('identity_card',valueImg)
        dataPassport.append('photo_self',valueImgWithYourIdentityCard)
        dataPassport.append('pass_number',dataKyc?.passportNumber)
        dataPassport.append('surename',dataKyc?.surname)
        dataPassport.append('address',dataKyc?.address)
        dataPassport.append('gender',dataKyc?.gender)
        dataPassport.append('email',dataKyc?.email)
        dataPassport.append('phone',dataKyc?.phoneNumber)
        dataPassport.append('nationality',dataKyc?.nationality)
        dataPassport.append('date_of_birth',dataKyc?.dateOfBirth)
        dataPassport.append('place_of_issue',dataKyc?.placeOfIssue)
        dataPassport.append('date_of_issue',dataKyc?.dateOfIssue)
        dataPassport.append('date_of_expiry',dataKyc?.dateOfExpiry)
        dataPassport.append('identity',dataKyc?.indentityValue)

        
        if(valueImg === 'No file is selected' || valueImgWithYourIdentityCard === 'No file is selected'){
            setShow(true)
            setTimeout(()=>{
                setShow(false)
            },3000)
            console.log('please submit your identity card')

        }
        else if (imgCardSize > 7000000 || imgPhotoSelfSize >7000000){
            console.log('IMAGE SIZE MORE THAN 7MB')
            toast.warning('Your file size exceed maximum size (7MB)')

        }else if (checked === false){
            toast.error('Please check to confirm all the data are real and you agree with Term & Condition')
        }
        else{
            if(checked === true){
                if(dataKyc?.indentityValue === "KTP"){
                    const response = await checkVerifyKTPAPI(dataKTP)
                    console.log('country ----',dataKTP?.country)
                    if(response?.success === false){
                        toast.error('make sure you input the correct data')
                    }else {
                        console.log('KTP :',dataKTP)
                        localStorage.setItem('kyc-information',JSON.stringify(dataKTP))
                        handleClick()
                    }
                   
                }
                else{
                    const response = await checkVerifyPassportAPI(dataPassport)
                    if(response?.success === false){
                        toast.error('make sure you input the correct data')
                    }else{
                        console.log('Passport',dataPassport)
                        localStorage.setItem('kyc-information',JSON.stringify(dataPassport))
                        handleClick();
                    }
                    
                }

            }else{
                toast.error('Please check to confirm all the data are real and you agree with Term & Condition')
            }
            

            
        }

    }

    console.log('WITH YOUR IDENTITY INFO----',valueImgWithYourIdentityCard)
  return (
    <div>
    <Navbar/>
    <div className='verification_step_3_wrapper d-flex flex-column justify-content-center mx-auto'>
     <Container className='container_verification_step_3_form d-flex flex-column justify-content-center align-items-center px-0'>
     <Row className='d-flex flex-column w-100 pb-5'>
            <h2 className='title_step_3 d-flex justify-content-center w-auto' style={{color:'white', fontSize:'38px', fontWeight:'500', fontFamily:'inter'}}>Verified your account</h2>
            <h2 className='subtitile_step_3 d-flex justify-content-center w-auto texts-align-center' style={{fontSize:'20px', fontFamily:'inter', fontWeight:'300', color:'white'}}>Please complete all of the forms below to verify your account</h2>
        </Row>
        <div className="verification_step_3__form d-flex flex-column w-75">
             <Row>
                 <Col className='px-5 pb-5'>
                     <h2 className='pt-3 d-flex' style={{fontSize:'32px', fontFamily:'inter', fontWeight:'500', color:'#2C3131'}}>Attachment (3/3)</h2>

                     <h2 className='mt-1 d-flex' style={{fontSize:'20px', fontWeight:'300', fontFamily:'inter', color:'#A9A9A9'}}>Please complete the attachment below</h2>

                     <hr />
                     
                     <h2 className='mt-1 d-flex' style={{fontSize:'16px', fontWeight:'500', fontFamily:'inter', color:'#2C3131'}}>Upload your identity card or passport</h2>

                     <h2 className='mt-1 d-flex' style={{fontSize:'14px', fontWeight:'300', fontFamily:'inter', color:'#2C3131'}}>Please upload photo of your identity card or passport. Supports: JPG, PNG, JPEG. Max. size 5 Mb</h2>
                     
                    <div className="upload_identity-card d-flex flex-row w-100 border p-3 align-items-center">
                    <input
                        type="file"
                        accept="image/*"
                        style={{display:'none'}}
                        id="contained-button-file"
                        onChange={handlerImgIdentityForm}
                        />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" component="span"    className='upload_button_identity_card align-items-center' 
                        value={valueImg} 
                        style={{backgroundColor:'#34495E', color:'white', fontSize:'14px', fontFamily:'inter', fontWeight:'300', borderRadius:'6px', width:'108px', height:'32px', zIndex:'1'}}>
                            Upload
                        </Button>
                    </label>
                        <h2 className='img_name d-flex ps-3 align-items-center my-auto' style={{fontSize:'20px', fontWeight:'300', fontFamily:'inter' , color:'#A9A9A9'}}>{valueImg}</h2>
                    </div>


                    <h2 className='mt-3 d-flex' style={{fontSize:'16px', fontWeight:'500', fontFamily:'inter', color:'#2C3131'}}>Upload Photo of you with your identity card or passport </h2>

                     <h2 className='mt-1 d-flex' style={{fontSize:'14px', fontWeight:'300', fontFamily:'inter', color:'#2C3131'}}>Please upload photo of you with your identity card or passport. Supports: JPG, PNG, JPEG. Max. size 5 Mb</h2>
                     
                    <div className="upload_identity-card d-flex flex-row w-100 border p-3 align-items-center">
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file-user-and-identity"
                        onChange={handlerImgWithIdentityForm}
                    />

                    <label htmlFor="contained-button-file-user-and-identity">
                        <Button variant="contained" component="span" className='upload_button_identity_card_with_user align-items-center' 
                        style={{backgroundColor:'#34495E', color:'white', fontSize:'14px', fontFamily:'inter', fontWeight:'300', borderRadius:'6px', width:'108px', height:'32px'}}>
                        Upload
                        </Button>
                    </label>
                        <h2 className='img_name d-flex ps-3 align-items-center my-auto' style={{fontSize:'20px', fontWeight:'300', fontFamily:'inter' , color:'#A9A9A9'}}>{valueImgWithYourIdentityCard}</h2>
                    </div>

                  

                    <Form className='mt-4 d-flex flex-row'>
                            {['checkbox'].map((type) => (
                                <div key={`default-${type}`} className="d-flex flex-row align-items-center">
                                <Form.Check 
                                    type={type}
                                    id={`default-${type}`}
                                    onChange={()=>setChecked(true)}
                                />
                                 <h2 className='px-3 my-auto' style={{fontSize:'16px', fontWeight:'300', fontFamily:'inter'}}>All data is real and I agree with <a href="/term_condition_grahakpg" className='term_services_register'><span className='term_services'>GrahaKPG Terms & Condition</span></a></h2>
                                </div>

                            ))}
                    </Form>
                    <Row>
                    {
                    show?<Alert className="nothavewallet" variant="danger">
                    {/* <img  className="alertlogo" src={alertLogo}/> */}
                    <p className="nothavewallettext">
                     Please make sure you've upload your identity card image
                    </p>

                    </Alert>:null
                    }
                    </Row>

                     <Row className='d-flex flex-row'>
                         <Col className='button_wrapper d-flex flex-row align-items-text justify-content-between'>

                         <a href='/verification_2_grahakpg' onClick={onSubmitBack} className='a_button_back w-100 pe-3'><button  className='w-100 mt-4 button_back' >Back</button></a>

                         <a onClick={onSubmit} className='a_button_next w-100 ps-3'><button  className='w-100 mt-4 button_next' >Next</button></a>

                         </Col>
                     </Row>

                 </Col>
             </Row>

         </div>
         <ToastContainer/>

     </Container>
     <FooterVerifyAccount/>
     </div>
 </div>
  )
}

export default VerificationStep3