import axios from "axios";
import CallGrahaAPI from "../config/api/configApi";



const ROOT_API = process.env.REACT_APP_GRAHA_URL;
const API = 'api'

export async function checkRatesApi() {
    const ENDPOINT = 'rate'

    const response = await axios.get(`${ROOT_API}/${API}/${ENDPOINT}`)
    const axiosResponse = response.data

    return axiosResponse.data
}

export async function checkByPublicAddressApi(user) {

    const ENDPOINT = 'user_address'


    // console.log("check public address on service",user)
    const url = `${ROOT_API}/${API}/${ENDPOINT}`;

    let address = user?.public_address;
    // console.log('address:',address)

    const requestCheckAddress = new FormData()

    requestCheckAddress.append('public_address', address)
    // console.log('call api:',requestCheckAddress)

    const response = await axios.post(`${ROOT_API}/${API}/${ENDPOINT}`, user).catch((err) => err.response)
    console.log('response api', response)

    console.log('response message-------------', response?.data?.message)
    console.log('response name-------------', response?.data?.data?.name)
    console.log('response phone-------------', response?.data?.data?.phone)
    console.log('response email-------------', response?.data?.data?.email)
    console.log('response status account-------------', response?.data?.data?.account_status)


    if (response.status > 300) {

        const res = {
            error: true,
            message: response?.data?.message,
            status: response?.data?.status,
            user
        }
        return res
    }

    const res = {
        error: false,
        message: response.data?.message,
        status: response?.data?.status,
        name: response?.data?.data?.name,
        email: response?.data?.data?.email,
        phone: response?.data?.data?.phone,
        account_status: response?.data?.data?.account_status,
        user
    }
    return res
}

export async function registerPublicAddress(dataRegister) {
    const ENDPOINT = 'register'

    const response = await axios.post(`${ROOT_API}/${API}/${ENDPOINT}`, dataRegister)
        .catch((err) => err.response)

    console.log('API RESPONSE REGISTER', response)

    if (response.status > 300) {
        const res = {
            success: false,
            dataRegister: null,
            message: response.data

        }
        return res
    }
    const res = {
        success: true,
        dataRegister,
        message: response.data?.message ?? response?.data?.error,

    }
    return res
}

export async function checkVerifyKTPAPI(dataKtp) {
    const ENDPOINT = 'verify_ktp'

    console.log('data KTP', dataKtp)

    const response = await axios.post(`${ROOT_API}/${API}/${ENDPOINT}`, dataKtp)
        .catch((err) => err.response)

    console.log('API RESPONSE REGISTER', response)

    if (response.status > 300) {
        const res = {
            success: false,
            dataKtp: null,
            message: response.data

        }
        return res
    }
    const res = {
        success: true,
        dataKtp,
        message: response.data?.message ?? response?.data?.error,

    }
    return res
}
export async function checkVerifyPassportAPI(dataPassport) {
    const ENDPOINT = 'verify_passport'

    console.log('data KTP', dataPassport)

    const response = await axios.post(`${ROOT_API}/${API}/${ENDPOINT}`, dataPassport)
        .catch((err) => err.response)

    console.log('API RESPONSE REGISTER', response)

    if (response.status > 300) {
        const res = {
            success: false,
            dataPassport: null,
            message: response.data

        }
        return res
    }
    const res = {
        success: true,
        dataPassport,
        message: response.data?.message ?? response?.data?.error,

    }
    return res
}
export async function checkPaymentMethod() {
    const ENDPOINT = 'paymentchannel'
    const response = await axios.get(`${ROOT_API}/${API}/${ENDPOINT}`)
    const axiosResponse = response.data

    return axiosResponse.data
}

export async function buyKepeng(dataBuy) {
    const ENDPOINT = 'buy'


    console.log('data-buy:', dataBuy)

    // const requestBuyForm = new FormData()

    // requestBuyForm.append('public_address',dataBuy.public_address);

    // requestBuyForm.append('rate',dataBuy.rate);

    // requestBuyForm.append('quantity',dataBuy.quantity);

    // requestBuyForm.append('payment_method',dataBuy.payment_method);

    // console.log('buy form ---------',requestBuyForm)


    const response = await axios.post(`${ROOT_API}/${API}/${ENDPOINT}`, dataBuy)
        .catch((err) => err.response)
    console.log('api res buy', response.data)
    // console.log('response status', response.status)
    // console.log('response msg',response.data?.message)
    console.log('VA Number CHECK API', response.data.data?.payment_code)
    console.log('status payment CHECK API', response.data.data?.status)
    console.log('Order ID', response?.data?.data?.total)
    console.log('batas waktu', response?.data?.message)
    console.log('url PG--', response?.data?.data?.url)

    if (response.status > 300) {

        const res = {
            success: false,
            dataBuy: null,
            message: response.data?.message ?? response?.data?.error,

        }
        return res
    }

    const res = {
        success: true,
        message: response.data?.message ?? response?.data?.error,
        dataBuy,
        statusPayment: response.data.data?.status,
        virtualAccountNumber: response.data.data?.payment_code,
        total: response?.data?.data?.total,
        url: response?.data?.data?.url,
        time: response?.data.data.exp_date,
        transaction_id: response?.data?.data?.code



    }
    return res

}

export async function sellKepeng(datasSell) {
    const ENDPOINT = 'sell'


    console.log('data---------:', datasSell)


    const response = await axios.post(`${ROOT_API}/${API}/${ENDPOINT}`, datasSell)
        .catch((err) => err.response)
    // const responseSellJSON = JSON.parse(response?.data)

    console.log('response data', response?.data)
    console.log('response sell', response)
    // console.log('VA Number CHECK API', response?.data?.data?.payment_code)
    console.log('status payment CHECK API', response?.data?.data?.status)
    console.log('data time', response?.data?.data?.exp_date)
    console.log("transaction_id:", response?.data?.data)



    if (response.status > 300) {

        const res = {
            error: true,
            message: response.data?.message,
            datasSell: null,
            statusTransfer: response?.data?.data?.status,
            time: response?.data?.data?.exp_date


        }
        return res
    }

    const res = {
        error: false,
        message: response.data?.message,
        datasSell: response.data,
        statusTransfer: response?.data?.data?.status,
        time: response?.data?.data?.exp_date,
        transfer_to: response?.data?.data?.transfer_to,
        transaction_id: response?.data?.data?.code





    }
    return res

}
export async function checkHistoryAPI(dataHistory) {
    const ENDPOINT = 'history'


    console.log('data:-------', dataHistory)


    const response = await axios.post(`${ROOT_API}/${API}/${ENDPOINT}`, dataHistory)
        .catch((err) => err.response)
    // console.log('response status', response.status)
    // console.log('response sell',response)
    // console.log('VA Number CHECK API', response.data.data.payment_code)
    // console.log('status payment CHECK API', response.data.data.status)
    // console.log('data', response.data)



    if (response.status > 300) {

        const res = {
            error: true,
            message: response.data?.error,
            dataHistory: null,

        }
        return res
    }

    const res = {
        error: false,
        message: response.data?.message,
        dataHistory: response.data,
    }
    return res

}
export async function getDetailHistoryTransaction(id) {
    const ENDPOINT = `detail_transaction/?id=${id}`
    const response = await axios.get(`${ROOT_API}/${API}/${ENDPOINT}`)
    const axiosResponse = response.data

    return axiosResponse.data
}