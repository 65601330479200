import React from 'react'
import GrahaFooterLogo from '../../assets/type-b white 1.png'
import {Container} from 'react-bootstrap'
import GrahaLogoText from '../../assets/GrahaLogoText.png'
import phoneContactLogo from '../../assets/phone.png'
import emailContactLogo from '../../assets/email.png'
import instagramLogo from '../../assets/instagram.png'
import facebookLogo from '../../assets/facebook.png'
import './TermConditionFooter.css'

const TermConditionFooter = () => {
  return (
    <div className='term-condition-footer-wrapper'>
        <Container>
        <div className="term-condition-footer-info">
            <hr className='line-footer'/>
                <img className='graha-logo-footer' src={GrahaFooterLogo} alt="/" />     
                <img className='graha-logo-Text-footer' src={GrahaLogoText} alt="/" />         
                <h4 className='text-1-footer'>Jl. Mulawarman, Dauh Puri Kaje</h4> 
                <h4 className='text-2-footer'>Kec. Denpasar Utara, Kota Denpasar, Bali 80231</h4>
                <div className="menu-footer">
                <a  href="/token_smart_contract"> <h4 className='token-smart-contract-footer'>Token Smart Contract</h4></a>
                  <h4 className='faq-footer'>FAQ</h4>
                  {/* <h4 className='about-footer'>About</h4>
                  <h4 className='report-footer'>Report</h4> */}
                  <a href='/term_condition_grahakpg'><h4 className='term-condition-footer'>Term & Condition</h4></a>
                  <a href='/privacy_policy_grahakpg'><h4 className='privacy-policy-footer'>Privacy Policy</h4></a>
                </div>
                <div className="contactLogo">
                <a href='https://wa.me/+6281383690090' target="_blank"><img className='phone-contact-footer' src={phoneContactLogo} alt="/" /></a>
                <a href='mailto:admin@grahakpg.com' target="_blank"><img className='email-contact-footer' src={emailContactLogo} alt="/" /></a> 
                <a href='https://instagram.com/grahakpg?igshid=YmMyMTA2M2Y=' target="_blank"><img className='instagram-contact-footer' src={instagramLogo} alt="/" /></a>
                <a href='https://www.facebook.com/profile.php?id=100079333405862' target="_blank"><img className='facebook-contact-footer' src={facebookLogo} alt="/" /> </a>
                
                </div>
            </div>
        </Container>
    </div>
  )
}

export default TermConditionFooter